import React, {useEffect, useState} from "react";
import "./style.css"
import Button from "../../components/button/Button";

interface IProps {
    reviews: string[];
    folder?: string,
    maxCount: number,
    linkVk?: string
    text?:string
}

const START_MAX = 4

const ReviewsCarousel = ({reviews, maxCount, linkVk, text ="Отзывы" }: IProps) => {
    let count = reviews?.length

    const [limit, setLimit] = useState(maxCount)

    useEffect(() => {
        if (count < limit) setLimit(count)
    }, [count])

    return (
        <section className={"reviews"}>
            <h2>{text}</h2>

            {count < 2 ?
                <div className={"reviewsColumn"}>
                    {reviews.map((el, i) =>
                        <img src={el} alt={el} key={i}/>
                    )}
                </div> :
                <div className={"reviewsContainer"}>
                    <div className={"reviewsColumn"}>
                        {reviews.map((el, i) => i < limit / 2 &&
                            <img src={el} alt={el} key={i}/>
                        )}
                    </div>
                    <div className={"reviewsColumn"}>
                        {reviews.map((el, i) => i >= limit / 2 && i < limit &&
                            <img src={el} alt={el} key={i}/>
                        )}
                    </div>
                </div>
            }

            {linkVk ? <Button onClick={() => window.open(linkVk)} text={"показать все"}/> :
                count > START_MAX && count !== limit &&
                <Button onClick={() => setLimit(count)} text={"показать все"}/>
            }
        </section>
    );
};

export default ReviewsCarousel;
