import React from "react";

function Privacy() {
    const htmlraw = `<p style="text-align: center;"><span style="font-weight: 400;">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ ВЕБ-САЙТА</span></p>
<ol>
<li><span style="font-weight: 400;"> ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</span></li>
</ol>
<p><span style="font-weight: 400;">&laquo;Закон&laquo; означает Федеральный закон Российской Федерации &laquo;О персональных данных&laquo; со всеми изменениями и дополнениями, а также иные законодательные акты Российской Федерации.</span></p>
<p><span style="font-weight: 400;">&laquo;Сайт&laquo; означает информационную единицу в Интернете, ресурс из веб-страниц (документов), которые объединены общей темой и связаны между друг с другом с помощью ссылок. Он зарегистрирован на Правообладателя и обязательно привязан к конкретному домену, являющемуся его адресом. Настоящая Политика разработана для следующего сайта: http://nysacademy.ru/</span></p>
<p><span style="font-weight: 400;">&laquo;Персональные данные&laquo; означает совокупность личных данных и/или неперсонифицированной информации о Пользователе, предоставляемую им самим Правообладателю и/или автоматически собираемую Правообладателем и/или третьими лицами.</span></p>
<p><span style="font-weight: 400;">&laquo;Политика&laquo; означает настоящею Политику конфиденциальности для веб-сайта (со всеми существующими дополнениями и изменениями).</span></p>
<p><span style="font-weight: 400;">&laquo;Пользователь&laquo; означает юридическое или физическое лицо, которое пользуется Сайтом и/или получило к нему доступ с какого-либо устройства.</span></p>
<p><span style="font-weight: 400;">&laquo;Пользовательское соглашение&laquo; означает соглашение, заключаемое между Правообладателем и Пользователем в отношении порядка, правил и особенностей использования Сайта. Пользователь присоединяется к такому соглашению и не имеет права вносить и/или требовать внесения в него каких-либо изменений или дополнений.</span></p>
<p><span style="font-weight: 400;">&laquo;Правообладатель&laquo; означает следующее лицо, на имя которого зарегистрирован Сайт:</span></p>
<p><span style="font-weight: 400;">Индивидуальный предприниматель Зверев Никита Сергеевич</span></p>
<p><span style="font-weight: 400;">ОГРН 322784700233364</span></p>
<p><span style="font-weight: 400;">ИНН 781131547487</span></p>
<p><span style="font-weight: 400;">Рс 40802810300003590498</span></p>
<p><span style="font-weight: 400;">БИК 044525974</span></p>
<p><span style="font-weight: 400;">АО &laquo;ТИНЬКОФФ БАНК&raquo;</span></p>
<p><span style="font-weight: 400;">Кор. счет 30101810145250000974</span></p>
<p><span style="font-weight: 400;">Адрес: Москва, 127287, ул. Хуторская 2-я, д. 38А, стр 26</span></p>
<p><span style="font-weight: 400;">&laquo;Файлы куки&laquo; означает небольшие файлы, отправляемые каким-либо сайтом, и размещаемые на компьютерах, смартфонах, планшетах, часах и других мобильных устройствах Пользователя, для улучшения работы сайтов, а также качества размещенного в них контента.</span></p>
<ol start="2">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА</span></li>
</ol>
<p><span style="font-weight: 400;">Общие положения</span></p>
<p><span style="font-weight: 400;">Данная Политика используется и применима исключительно к Персональным данным, получаемым от Пользователя в связи с использованием им Сайта. Положения данной Политики направлены на:</span></p>
<p><span style="font-weight: 400;">(1) определение видов и типов получаемых Персональных данных, направлений и целей использования (обработки) Персональных данных, а также источников получения таких Персональных данных; и</span></p>
<p><span style="font-weight: 400;">(2) определение прав Пользователя в отношении защиты конфиденциальности передаваемых им Персональных данных; и</span></p>
<p><span style="font-weight: 400;">(3) определение лиц, ответственных за обработку и хранение Персональных данных, а также третьих лиц, которым такие данные раскрываются (полностью или частично).</span></p>
<p><span style="font-weight: 400;">Правила настоящей Политики не применяются в случае обработки третьими лицами Персональных данных, которые добровольно предоставляются Пользователем.</span></p>
<p><span style="font-weight: 400;">Посредством использования Сайта Пользователь соглашается с условиями данной Политики и дает свое согласие Правообладателю на сбор, обработку, удержание и хранение Персональных данных в порядке и на условиях, предусмотренных настоящей Политикой.</span></p>
<p><span style="font-weight: 400;">Если Пользователь не согласен с условиями Политики и/или отдельные условия Политики ему не понятны, в таком случае Пользователь обязан немедленно прекратить использование Сайта.</span></p>
<p><span style="font-weight: 400;">Права пользователя по защите персональных данных</span></p>
<p><span style="font-weight: 400;">В связи с предоставлением Персональных данных Пользователь автоматически получает следующие права:</span></p>
<p><span style="font-weight: 400;">(1) получать данные, касающиеся их обработки (основания и цели такой обработки, применяемые способы обработки, сведения о лицах, которые имеют доступ к ним или которым они могут быть раскрыты на основании договора или Закона).</span></p>
<p><span style="font-weight: 400;">(2) получать данные о месте нахождения и идентификационных данных лиц, совершающих обработку Персональных данных.</span></p>
<p><span style="font-weight: 400;">(3) получать данные о сроках хранения Персональных данных.</span></p>
<p><span style="font-weight: 400;">(4) получать данные об осуществленной или о предполагаемой трансграничной передаче Персональных данных.</span></p>
<p><span style="font-weight: 400;">(5) обжаловать действия или бездействие Правообладателя в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</span></p>
<p><span style="font-weight: 400;">(6) получать возмещение убытков и/или компенсацию морального вреда в судебном порядке в следствие допущенных Правообладателем и/или третьими лицами нарушений прав Пользователя на охрану и защиту его Персональных данных.</span></p>
<p><span style="font-weight: 400;">(7) реализовывать иные права в области защиты персональных данных, предусмотренные Законом или положениями данной Политики.</span></p>
<ol start="3">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></li>
</ol>
<p><span style="font-weight: 400;">Неперсонифицированная информация о пользователях</span></p>
<p><span style="font-weight: 400;">В связи с использованием Сайта Правообладатель может автоматически собирать и обрабатывать следующею неперсонифицированную информацию о Пользователе:</span></p>
<p><span style="font-weight: 400;">(1) информацию о трафике, возможном количестве совершенных кликов, логи и другие данные.</span></p>
<p><span style="font-weight: 400;">(2) информацию об устройстве (идентификационный номер устройства, с которого выполняется вход, операционная система, платформа, тип браузера и другая информация о браузере, IP адрес).</span></p>
<p><span style="font-weight: 400;">Личные данные о пользователях</span></p>
<p><span style="font-weight: 400;">Пользователь предоставляет Правообладателю о себе следующие личные данные:</span></p>
<p><span style="font-weight: 400;">(1) адрес электронной почты.</span></p>
<p><span style="font-weight: 400;">(2) номер мобильного телефона.</span></p>
<p><span style="font-weight: 400;">(3) данные из следующей социальной сети: Vkontakte. Правообладатель может собирать, обрабатывать и хранить ID Пользователя любой из социальных сетей, которая была использована Пользователем при регистрации и/или входе на Сайт. Если регистрация Пользователя на Сайте осуществляется с помощью одного из его аккаунтов в социальных сетях, Пользователь тем самым предоставляет Правообладателю автоматическое согласие на сбор, обработку и хранение Персональных данных, которые стали доступны Правообладателю через выбранную социальную сеть.</span></p>
<p><span style="font-weight: 400;">(4) данные, которые содержатся в личном кабинете (профиле) Пользователя, все внутренние переписки Пользователя (если есть), а также иная активность личного кабинета (профиля) Пользователя.</span></p>
<p><span style="font-weight: 400;">(5) данные о совершенных Пользователям заказах и/или полученных/оплаченных услугах через Сайт.</span></p>
<p><span style="font-weight: 400;">(6) данные и информация, получаемая в результате объединения тех или иных Персональных данных конкретного Пользователя, а также данные и информация, получаемые данные о Пользователе, получаемые от третьих лиц (партнеров, маркетологов, исследователей).</span></p>
<p><span style="font-weight: 400;">Пользователь является единственным ответственным лицом за полноту предоставляемых личных (персональных) данных и обязан осуществлять своевременное их изменение (обновление, проверку, корректировку) на регулярной основе.</span></p>
<p><span style="font-weight: 400;">Правообладатель исходит из того, что все предоставленные Пользователем личные (персональные) данные являются достоверными, и что Пользователь поддерживает такую информацию в актуальном состоянии.</span></p>
<p><span style="font-weight: 400;">Информация о совершаемых транзакциях</span></p>
<p><span style="font-weight: 400;">Пользователь через Сайт может осуществлять оплату за товары или услуги, посредством введения в специальное поле информации о платежной карте и идентификационных данных собственника такой карты. Пользователь может совершать оплату следующим способом:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">с помощью платежной системы Т-банка.</span></li>
</ul>
<p><span style="font-weight: 400;">Сбор и обработка данных о Пользователе в данном случае осуществляется исключительно для целей проведения оплаты, недопущения мошенничества, а также соблюдения иных требований Закона.</span></p>
<p><span style="font-weight: 400;">Пользователь дает свое согласие на доступ и сбор Правообладателем и соответствующей платежной системой или банковским учреждением, через которую/которое проводится оплата, к таким Персональным данным, а также соглашается с политикой конфиденциальности соответствующей платежной системы или банковского учреждения.</span></p>
<p><span style="font-weight: 400;">Использование файлов куки</span></p>
<p><span style="font-weight: 400;">Сайт применяет определенные Файлы куки для сохранения IP-адреса, предпочтений Пользователей или типа используемого устройства с целью (1) ведения статистики посещений и трафика сайта, и (2) персонализации выводимых на экран Пользователя данных, и (3) сохранения данных, необходимых для идентификации Пользователя, в том числе при доступе с разных устройств, и (4) показа рекламы в соответствии с интересами и предпочтениями Пользователя. Сайт может использовать как собственные Файлы куки, принадлежащие Правообладателю, так и Файлы куки третьих лиц.</span></p>
<p><span style="font-weight: 400;">Перечень используемых Файлов куки, способ их взаимодействия с устройством Пользователя, а также вид и объем получаемых Персональных данных, регулируется отдельной Политикой конфиденциальности куки-файлов, с которой можно ознакомиться по следующей ссылке: http://nysacademy.ru/hooks.</span></p>
<ol start="4">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></li>
</ol>
<p><span style="font-weight: 400;">Определение целей обработки</span></p>
<p><span style="font-weight: 400;">Сбор и обработка Персональных данных осуществляется в следующих целях:</span></p>
<p><span style="font-weight: 400;">(1) для анализа поведения Пользователя, а также выявления предпочтений Пользователя к определенному виду контента.</span></p>
<p><span style="font-weight: 400;">(2) для оперативной и корректной работы Сайта, улучшения его функциональности и и контента.</span></p>
<p><span style="font-weight: 400;">(3) для идентификации Пользователя.</span></p>
<p><span style="font-weight: 400;">(4) для направления персонализированных рекламных и маркетинговых материалов на указанный электронный адрес и/или мобильный телефон Пользователя.</span></p>
<p><span style="font-weight: 400;">(5) для соблюдения требований Закона.</span></p>
<p><span style="font-weight: 400;">(6) для отслеживания заказов, совершенных Пользователем через Сайт.</span></p>
<p><span style="font-weight: 400;">(7) для технической поддержки Сайта, выявления проблем в его работе и их устранение.</span></p>
<p><span style="font-weight: 400;">(8) для поддержания связи с Пользователем (коммуникация).</span></p>
<p><span style="font-weight: 400;">(9) для выполнения иных обязательств Правообладателя, которые возникли перед Пользователем.</span></p>
<p><span style="font-weight: 400;">(10) для любых других целей, при условии получения отдельного согласия от Пользователя.</span></p>
<p><span style="font-weight: 400;">Обработка Персональных данных осуществляется на основе принципов: (1) законности целей и способов обработки; и (2) добросовестности; и (3) соответствия целей обработки Персональных данных целям, заранее определенным и заявленным при сборе таких Персональных данных; и (4) соответствия объема и характера обрабатываемых Персональных данных заявленным целям их обработки.</span></p>
<p><span style="font-weight: 400;">Условия обработки персональных данных</span></p>
<p><span style="font-weight: 400;">Обработка Персональных данных проводится в случаях: (1) получения согласия от Пользователя; или (2) достижения Правообладателем целей, предусмотренных международным договором или Законом; или (3) предоставления Пользователем своих Персональных данных неограниченному кругу лицу; или (4) выполнения иных обязательств Правообладателя перед Пользователем, включая, однако не ограничиваясь, предоставление определенного контента Пользователю; или (5) спасения жизни или здоровья Пользователя, когда согласие на обработку его Персональных данных не удается получить заблаговременно.</span></p>
<p><span style="font-weight: 400;">В случае обезличивания Персональных данных, что не позволяет прямо или опосредованно определить Пользователя, последующее использование и раскрытие таких данных третьим лицам допускается и в отношении их более не применяются правила данной Политики.</span></p>
<p><span style="font-weight: 400;">Правообладатель принимает все возможные меры для защиты конфиденциальности полученных Персональных данных, за исключением случаев, когда Пользователь сделал такие данные общедоступными.</span></p>
<p><span style="font-weight: 400;">Обработка Персональных данных осуществляется с использованием средств автоматизации и без использования таких средств автоматизации.</span></p>
<ol start="5">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ</span></li>
</ol>
<p><span style="font-weight: 400;">Использование аналитических платформ</span></p>
<p><span style="font-weight: 400;">Правообладатель использует аналитическую платформу Yandex для (1) отслеживания частоты посещаемости сайта Пользователями; и (2) отслеживания способов использования Пользователем Сайта и/или его контента; и (3) выявления типа и вида контента, который является популярными среди Пользователей; и (4) определения места нахождения Пользователя. Пользователь также дает свое согласие Правообладателю на использование им информации, полученной о Пользователе от Yandex.</span></p>
<p><span style="font-weight: 400;">Для указанных целей аналитическая платформа Yandex может собирать данные об IP адресе, геолокации, поведении Пользователя, а также его предпочтениях и интересе в отношении определенного контента.</span></p>
<p><span style="font-weight: 400;">Аналитическая платформа Yandex получает доступ к Персональным данным с тем, чтоб предоставить Правообладателю понимание того насколько эффективно работает его Сайт, какой именно контент является популярным, насколько эффективно размещение в нем той или иной рекламы, а также для целей разработки и/или улучшения существующей маркетинговой стратегии Правообладателя.</span></p>
<p><span style="font-weight: 400;">С момента использования Сайта Пользователь соглашается с политикой конфиденциальности (Privacy Policy) Yandex, а также с автоматической установкой на устройство Пользователя соответствующих Файлов куки.</span></p>
<p><span style="font-weight: 400;">Раскрытие персональных данных третьим лицам</span></p>
<p><span style="font-weight: 400;">Правообладатель имеет право раскрывать Персональные данные (1) своим аффилированным лицам, филиалам и представительствам, открытым как на территории Российской Федерации, так и на территории других государств; (2) правопреемникам Правообладателя, которые возникли в результате его ликвидации, реорганизации или банкротства, и которые получили исключительные права владения Сайтом; (3) поставщикам платежных услуг или банковским (финансовым) учреждениям, для проведения транзакций Пользователя через Сайт; (4) третьим лицам исключительно для целей оказания получения Пользователем определенного контента или доступа к нему; (5) третьим лицам, когда Пользователем было дано согласие на раскрытие, передачу или обработку своих Персональных данных, а также в иных случаях, прямо предусмотренных Законом или данной Политикой.</span></p>
<p><span style="font-weight: 400;">Правообладатель раскрывает Персональные данные только в том случае, если (1) уверен, что третьи лица будут соблюдать условия данной Политики и предпринимать такие же меры по защите конфиденциальности Персональных данных, которые предпринимает сам Правообладатель, и (2) согласие на такое раскрытие было предварительно выражено Пользователем и/или допускается на основании Закона.</span></p>
<ol start="6">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> РАЗМЕЩЕНИЕ РЕКЛАМЫ</span></li>
</ol>
<p><span style="font-weight: 400;">Реклама на Сайте</span></p>
<p><span style="font-weight: 400;">Правообладатель не размещает рекламу на Сайте.</span></p>
<p><span style="font-weight: 400;">Рассылка рекламных материалов</span></p>
<p><span style="font-weight: 400;">Пользователь соглашается с правом Правообладателя отправлять на предоставленный электронный адрес и/или мобильный телефон персонализированные рекламные и маркетинговые материалы.</span></p>
<p><span style="font-weight: 400;">Пользователь имеет право в любой момент отказаться от получения таких рекламных и маркетинговых материалов посредством выполнения следующих действий:</span></p>
<p><em><span style="font-weight: 400;">Направить письмо с просьбой отключения от рассылки на почту: info@nysacademy.ru</span></em></p>
<p><span style="font-weight: 400;">Обращаем внимание, что в случае отказа от получения рекламных и маркетинговых материалов, Пользователь может продолжать получать от Правообладателя какие-либо уведомления, связанные с порядком использования Сайта и/или его контента.</span></p>
<ol start="7">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ</span></li>
</ol>
<p><span style="font-weight: 400;">Требование о прекращении обработки персональных данных</span></p>
<p><span style="font-weight: 400;">Каждый Пользователь имеет право выразить свое возражение Правообладателю против обработки и/или хранения его Персональных данных. Такое возражение может быть выражено следующим образом:</span></p>
<p><em><span style="font-weight: 400;">Запрос должен быть направлен Правообладателю на адрес info@nysacademy.ru</span></em></p>
<p><span style="font-weight: 400;">Запрос на получение информации о персональных данных</span></p>
<p><span style="font-weight: 400;">Если у Пользователя возникают вопросы, связанные с порядком применения или использования настоящий Политики, порядком и/или способом обработки Персональных данных, Пользователь может задать такой вопрос следующим образом:</span></p>
<p><em><span style="font-weight: 400;">По любым вопросам к Правообладателю писать на адреc: info@nysacademy.ru</span></em></p>
<p><span style="font-weight: 400;">Изменение (обновление, дополнение, корректировка) или удалении персональных данных</span></p>
<p><span style="font-weight: 400;">Пользователь имеет право в любой момент самостоятельно изменить или удалить свои Персональные данные, за исключением случаев, когда такое изменение или удаление может привести (1) к нарушению правил настоящей Политики; или (2) к нарушению Закона; или (3) характер таких Персональных данных является доказательством в каком-либо судебном процессе, возникшем между Правообладателем и Пользователем. Для этого Пользователю требуется удалить свой личный аккаунт (профиль) на Сайте.</span></p>
<p><span style="font-weight: 400;">Правообладатель имеет право в любой момент удалить личный аккаунт/профиль Пользователя, а также все Персональные данные о Пользователе, если он нарушил условия данной Политики и/или Пользовательского соглашения.</span></p>
<ol start="8">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></li>
</ol>
<p><span style="font-weight: 400;">Хранение осуществляется самостоятельно Правообладателем.</span></p>
<p><span style="font-weight: 400;">Хранение осуществляется в течение всего срока, необходимого для достижения заявленных целей обработки Персональных данных.</span></p>
<p><span style="font-weight: 400;">Правообладатель обязуется немедленно после достижения целей обработки Персональных данных уничтожить их или же обезличить.</span></p>
<ol start="9">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ДОСТУП НЕСОВЕРШЕННОЛЕТНИХ К САЙТУ</span></li>
</ol>
<p><span style="font-weight: 400;">Использование Сайта предназначено для лиц старше 18 лет, которые получают к нему доступ только при условии предоставления предварительного согласия на обработку их Персональных данных.</span></p>
<p><span style="font-weight: 400;">Если Пользователь является несовершеннолетним лицом, в таком случае он должен немедленно прекратить пользоваться Сайтом.</span></p>
<ol start="10">
<li><span style="font-weight: 400;"><br /></span> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></li>
</ol>
<p><span style="font-weight: 400;">Защита конфиденциальности Персональных данных является первостепенной и важной задачей для Правообладателя. Правообладатель придерживается всех требуемых международных стандартов, правил и рекомендаций по защите Персональных данных.</span></p>
<p><span style="font-weight: 400;">Правообладатель внедрил ряд технических и организационных методов, направленных на защиту Персональных данных от разглашения или несанкционированного доступа к ним третьих лиц. Для обеспечения сохранности и конфиденциальности получаемых Персональных данных Правообладатель использует следующие средства защиты:</span></p>
<p><span style="font-weight: 400;">(1) SSL (Security Sockets Layer) протокол.</span></p>
<p><span style="font-weight: 400;">(2) Firewalls.</span></p>
<p><span style="font-weight: 400;">(3) а также:</span></p>
<p><em><span style="font-weight: 400;">1.Шифрование данных &nbsp; &nbsp; &nbsp; &nbsp; </span></em><em><span style="font-weight: 400;"><br /></span></em><em><span style="font-weight: 400;"> 2.Защищенная база данных&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span></em><em><span style="font-weight: 400;"><br /></span></em><em><span style="font-weight: 400;"> 3.Установка пароля</span></em></p>
<ol start="11">
<li><em><span style="font-weight: 400;"><br /></span></em> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span></li>
</ol>
<p><span style="font-weight: 400;">Доступность текста политики для ознакомления</span></p>
<p><span style="font-weight: 400;">Пользователи могут ознакомиться с условиями данной Политики по следующей ссылке: https://nysacademy.ru/privacy_policy.</span></p>
<p><span style="font-weight: 400;">Данная редакция Политики действует от 1 июля 2022 годa.</span></p>
<p><span style="font-weight: 400;">Изменение и дополнение политики</span></p>
<p><span style="font-weight: 400;">Настоящая Политика может быть изменена время от времени. Правообладатель не несет какой-либо ответственности перед Пользователем за изменение условий данной Политики без разрешения и/или согласия Пользователя.</span></p>
<p><span style="font-weight: 400;">Пользователь сам обязуется на регулярной основе проверять положения данной Политики на предмет ее возможного изменения или дополнения. Однако, в случае существенного изменения условий данной Политики, Правообладатель обязуется уведомить Пользователей следующим способом:</span></p>
<p><em><span style="font-weight: 400;">Автоматическая рассылка на почту указанную в профиле.</span></em></p>
<p><span style="font-weight: 400;">Применимое законодательство</span></p>
<p><span style="font-weight: 400;">Настоящая Политика разработана в соответствие с действующим законодательством о защите персональных данных Российской Федерации, в частности, с нормами Федерального закона от 27 июля 2006 года № 152-ФЗ &laquo;О персональных данных&laquo; (со всеми дополнениями и изменениями), Федерального закона от 21 июля 2014 года № 242-ФЗ &laquo;О внесении изменений в отдельные законодательные акты Российской Федерации в части уточнения порядка обработки персональных данных в информационно-телекоммуникационных сетях&laquo; (со всеми дополнениями и изменениями).</span></p>
<p><span style="font-weight: 400;">Риск разглашения</span></p>
<p><span style="font-weight: 400;">Вне зависимости от предпринимаемых Правообладателем мер защиты конфиденциальности получаемых персональных данных, Пользователь настоящим считается должным образом ознакомлен с тем, что любая передача Персональных данных в сети Интернет не может быть гарантировано безопасной, а потому Пользователь осуществляет такую передачу на свой собственный риск.</span></p>
<p style="text-align: center;"><br /><br /></p>
`;
    return (
        <div
            dangerouslySetInnerHTML={{__html: htmlraw}}
            className="docs"
            style={{paddingRight: "5rem", paddingLeft: "5rem"}}
        ></div>
    );
}

export default Privacy;
