import React, {useState} from 'react';
import Button from "../../../../components/button/Button";
import {buyTinkoffSubscribe} from "../../../../service/api/subscribeApi";
import {Modal} from "../../../../stories/modal/Modal";
import FormRegister from "../../../../stories/FormRegister";

export const BuySubscribe = (props: any) => {
    const [open, setOpen] = useState(false)
    const [msg, setMsg] = useState("Присоединиться")
    const {item} = props

    const buySubscribe = async (item: any) => {
        try {
            const response = await buyTinkoffSubscribe(
                localStorage.getItem("token"),
                item?.subscribeId ?? 0
            );
            if (response?.data) {
                window.location.href = response.data
            }
        }catch (e) {
            setMsg("У вас есть эта подписка")
        }
    };

    const handleBuy = () => {
        if (!localStorage.getItem("token")) setOpen(true)
        else buySubscribe(item)
    }

    return (
        <>
            <Button
                onClick={handleBuy}
                style={{width: "auto", margin: "0 8px"}}
                text={msg}
            />

            <Modal isOpen={open} onClose={() => setOpen(false)}>
                <FormRegister courseCurrentPrice={item?.currentPrice} buyFunc={() => buySubscribe(item)}/>
            </Modal>
        </>
    );
};
