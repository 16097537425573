import React from "react";
import "../styles/componentStyles/etc/footer.css";
import {Link} from "react-router-dom";
import {ABOUT_PATH, ACCOUNT_PATH, NEWS_PATH} from "../service/const/paths";

function Footer() {

	return (
		<footer>
			<div className="footer-logo">
				<img src={"../../logo.png"} alt="" />
			</div>
			<div className="footer-links">
				<Link to={NEWS_PATH}>НОВОСТИ</Link>
				<Link to={ABOUT_PATH}>О НАС</Link>
				<Link to={ACCOUNT_PATH}>ЛИЧНЫЙ КАБИНЕТ</Link>
				<Link to="/oferta">ПУБЛИЧНАЯ ОФЕРТА</Link>
				<Link to="/privacy_policy">ПОЛИТИКА КОНФИДЕЦИАЛЬНОСТИ</Link>
			</div>
			<div className="divider"></div>
			<div className="socials-icons flex">
				<a href="https://vk.com/nysacademy">
					<img src="../socials/vk_icon.png" alt="" />
				</a>
				<a href="https://www.youtube.com/channel/UCKwny_BSOEmQjNRIc7w7_JQ/">
					<img src="../socials/yt_icon.png" alt="" />
				</a>
				<a href="https://t.me/nysacademy">
					<img src="../socials/tg_icon.png" alt="" />
				</a>
			</div>
			<p className="footer-creds">
				ИП Зверев Н. С. ОГРН: 322784700233364 ИНН: 781131547487{" "}
			</p>
			<div className="footer-email py-2">
				<a href="mailto:">info@nysacademy.ru</a>
			</div>
		</footer>
	);
}

export default Footer;
