import React from 'react';
import {HOME_PATH} from "../service/const/paths";
import {useNavigate} from "react-router-dom";

export const LinkToSubscribe = () => {
    const navigate = useNavigate()

    const transferToRef = () => {
        navigate(HOME_PATH, {state: {ref: "subscriptions"}})
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%"}}>
            <div  onClick={transferToRef} className="button" style={{width: "auto"}}>
                <div>присоединиться</div>
            </div>
        </div>
    );
};

