import React from 'react';
import "./templateLanding.css"
import {MiniCard} from "./ui/MiniCard";
import img_for_bot from "./assets/img_for_bot.png"
import img_for_channel from "./assets/img_for_channel.png"
import icon from "./assets/telegram.png"
import useMobile from "../../service/hooks/useMobile";
import {Present} from "./ui/Present";

export enum TypeLanding {
    BOT = 'bot',
    CHANNEL = 'channel'
}

export const TemplateLanding = (props: any) => {
    const {title, array, type, link} = props

    const isMobile = useMobile()

    if (isMobile) return (
        <div className={"container_landing"}>
            <div className={"container_table_two_columns"}>
                <div>
                    <p className={"yellow_title"}>
                        {title}
                    </p>

                    <div className={"container-img"} style={{height:isMobile ? "auto" : "100%"}}>
                        <img alt={"bot"} src={type === TypeLanding.BOT ? img_for_bot : img_for_channel}/>
                    </div>

                    <Present/>

                    <div className={"container-button"} style={{position:"fixed", bottom:20, left:0}}>
                        <div
                            className={"telegram-button"}
                            onClick={() => window.open(link)}
                        >
                            <div style={{display: 'flex', alignItems: "center", gap: 16}}>
                                <img style={{height: 36}} src={icon} alt={"icon"}/>
                                ПЕРЕЙТИ В {type === TypeLanding.BOT ? "БОТ" : "КАНАЛ"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"table_two_columns"} style={{paddingBottom:120}}>
                    {array.map((text: string, i: number) => <MiniCard key={i} text={text}/>)}
                </div>
            </div>
        </div>
    )

    return (
        <div className={"container_landing"}>
            <div className={"container_table_two_columns"}>
                <div>
                    <p className={"yellow_title"}>
                        {title}
                    </p>
                    <div className={"table_two_columns"}>
                        {array.map((text: string, i: number) => <MiniCard key={i} text={text}/>)}
                    </div>

                    <Present/>

                    <div className={"container-button"}>
                        <div
                            className={"telegram-button"}
                            onClick={() => window.open(link)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: "center",
                                    gap: 16
                                }}
                            >
                                <img style={{height: 36}} src={icon} alt={"icon"}/>
                                ПЕРЕЙТИ В {type === TypeLanding.BOT ? "БОТ" : "КАНАЛ"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"container-img"}>
                    <img alt={"bot"} src={type === TypeLanding.BOT ? img_for_bot : img_for_channel}/>
                </div>
            </div>
        </div>
    );
};
