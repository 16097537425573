import React from 'react';
import "../../../../styles/componentStyles/subscribe/buySubscribe.css";
import {BuySubscribe} from "./BuySubscribe";

const CardSubscribe = (props: any) => {
    const {item} = props

    const isOnlyTitle = item?.subItems.length > 0

    return (
        <div
            style={{
                display: 'flex',
                gap:"2rem",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid #FFFFFF",
                padding: "24px 16px",
                width: '100%',
                borderRadius: 25
            }}
        >
            <div
                style={{
                    display: 'flex',
                    gap:"2rem",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div style={{fontSize: 28}}>{item?.subscribeName}</div>

                <div style={{display: 'flex', alignItems: "center", justifyContent: 'center', gap: 16}}>
                    <div>
                        <div style={{
                            fontSize: 16,
                            background: "#DDB64B",
                            borderRadius: 25,
                            padding: "2px 8px",
                            color: "#000"
                        }}>-{item?.discount}%
                        </div>
                        <div style={{fontSize: 16, textDecoration: "line-through"}}>{item?.oldPrice}₽</div>
                    </div>
                    <div style={{fontSize: 24}}>{item?.currentPrice}₽{item.mouthsDuration ? "/месяц" : ""}</div>
                </div>


                <div style={{fontSize: isOnlyTitle ? 18 : 26, marginTop:!isOnlyTitle ? "2rem" : ""}}>{item?.subscribeDescription}</div>

                <div>
                    <ul style={{paddingLeft: "2rem", textAlign: "start"}}>
                        {item?.subItems.map((item: string) => <li
                            style={{fontSize: 14, listStyleType: "circle", fontStyle: "italic"}}>{item}</li>)}
                    </ul>
                </div>
            </div>

            <BuySubscribe item={item}/>
        </div>
    );
};

export default CardSubscribe;
