export const faq_vars = [
    {
        head: "Если у меня возникнут какие-то вопросы, то куда я могу обращаться?",
        content: "Вы можете написать ваш вопрос на почту info@nysacademy.ru. Ответ приходит либо в тот же день либо в течение 3 дней."
    },
    {
        head: "Я оплатил доступ. Как присоединиться к сообществу?",
        content: "Если вы оплатили доступ по первому тарифу, то вам придет письмо на почту с ссылкой на присоединение к сообществу. На тарифах «продвинутый» и «абсолютный» у вас откроется личный кабинет, в который вы можете перейти, кликнув по иконке в правом верхнем углу сайта. Там будут все необходимые материалы."
    },
    {
        head: "Что делать, если нет подходящего для меня способа оплаты?",
        content: "Напишите нам на почту info@nysacademy.ru и мы вместе рассмотрим возможные варианты."
    },
    {
        head: "Стоит ли присоединяться, если я не смогу посещать мероприятий вживую?",
        content: "Наше сообщество функционирует в основном в формате онлайн, поэтому вы в любом случае получите доступ к основному контенту. Кроме того, по мере развития мы будем осваивать новые регионы. Мы стараемся создать инфраструктуру вокруг каждого пользователя."
    },
    {
        head: "Тарифы “Продвинутый” и “Абсолютный” отличаются только временем подписки?",
        content: "Да, количество доступного материала на них одинаково. Разница лишь в том, что на тарифе “Продвинутый” вы получаете доступ ко всем материалам по месячной подписке, в то время как оплатив “Абсолютный” тариф, вы получаете доступ к тем же материалам, но уже навсегда."
    },
    {
        head: "Где будут располагаться курсы?",
        content: "После того как вы оплатите соответствующий доступ, все курсы будут сразу же доступны в вашем личном кабинете, в который вы можете перейти, кликнув по иконке в правом верхнем углу сайта."
    },
    {
        head: "Где находится сообщество?",
        content: "Наша основная площадка — закрытый телеграм-канал, где регулярно публикуется эксклюзивный контент, проводятся стримы и другие мероприятия. Кроме того, при оплате тарифа «Продвинутый» у вас открывается удобный личный кабинет на этом сайте, где находится огромное количество обучающих материалов по самым различным направлениям — от философии и истории до психологии и развития soft skills."
    },
    {
        head: "Какую основную ценность несет в себе академия “Нус”?",
        content: "Наша академия представляет собой одновременно лекторий, в котором находится огромное количество обучающего материала, и сообщество единомышленников. Наши курсы читают ведущие специалисты в своей области, а все участники становятся частью единого сообщества, где возможно создать прочные связи для реализации своих планов. Мы убеждены, что знания и связи — самые важные ресурсы, и именно их мы предоставляем нашим гостям."
    }
]
