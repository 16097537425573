import React from 'react';
import present from "../assets/present.png"
import "../templateLanding.css"

export const Present = () => {
    return (
        <div className={"present-container"}>
            <div>+ подарок: бесплатная лекция по практической психологии</div>
            <img style={{height:"5rem"}} alt={"present"} src={present}/>
        </div>
    );
};
