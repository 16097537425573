import {buyTinkoff} from "./api/buyApi";

export function getQueryVariable(params: object) {
    if (params) { // @ts-ignore
        return '?' + Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')
    }
    else return ""
}


export const buyCourse = async (ref:any, COURSEURL:string) => {
    if (!localStorage.getItem("token")) {
        if (ref?.current) {
            ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
        }
    } else {
        const response = await buyTinkoff(
            localStorage.getItem("token"),
            COURSEURL
        );
        if (response?.data) {
            if (response.data) {
                window.location.replace(response.data);
            }
        }
    }
};

type Mods = Record<string, boolean | string>;

export function classNames(cls: string, modes: Mods = {}, additional: string[] = []): string {
    return [
        cls,
        ...additional.filter(Boolean),
        ...Object.entries(modes)
            .filter(([, value]) => Boolean(value))
            .map(([className]) => className),
    ]
        .join(' ')
        .trim();
}
