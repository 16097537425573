import React, {useEffect, useState} from 'react';
import CardSubscribe from "./CardSubscribe";
import {getAllSubscribes} from "../../../../service/api/subscribeApi";

const ListSubscribes = () => {
    const [data, setData] = useState<any>(undefined)

    useEffect(() => {
        getAllSubscribes().then(res => setData(res))
    }, [])

    return (
        data?.length > 0 ?
            <div className="mt-8 mb-8 grid grid-cols-1 md:grid-cols-3 gap-8">
                {data?.map((item: any, i: number) => <CardSubscribe key={i} item={item}/>)}
            </div> : null
    );
};

export default ListSubscribes;
