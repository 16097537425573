import React, {useEffect, useState} from "react";
import useCourseInfo from "../../service/hooks/useCourseInfo";
import "../../styles/componentStyles/coursesPage/coursePlayer.css";
import {LONGREADS} from "../longreads/nonhuman_longreeds";
import {getContentBlocks, getFilesBlocks, getLongreads,} from "../../service/api/coursesApi";
import {AiFillFile} from "react-icons/ai";
import LongreadCard from "../../stories/longreads/LongreadCard";
import {API} from "../../service/const/api";

interface ICoursePlayerProps {
    courseURLId: string;
    purchasedBlocks: any[];
}

function extractNumbersFromLink(link: string) {
    const regex = /\d+/g;
    const match = link.match(regex);
    return match ? match.join("") : "";
}

function CoursePlayer({courseURLId}: ICoursePlayerProps) {
    const TOKEN = localStorage.getItem("token");
    const [allBlocks, setAllBlocks] = useState<any>(null);
    const [allBlocksTemp] = useCourseInfo(courseURLId);
    const [activeBlock, setActiveBlock] = useState<any>(null);
    const [currentBlockContent, setCurrentBlockContent] = useState<any>(null);
    const [currentLecture, setCurrentLecture] = useState<any>(0);
    const [currentBlockFiles, setCurrentBlockFiles] = useState<any>(null);
    const [currentLongreads, setCurrentLongreads] = useState<any>(null);

    useEffect(() => {
        if (allBlocksTemp.p) setAllBlocks(allBlocksTemp.p.b);
    }, [allBlocksTemp]);

    const selectBlock = (block: any) => {
        setActiveBlock(block);
        setCurrentLecture(0);
        if (!currentBlockContent) setCurrentBlockContent(null);
    };

    useEffect(() => {
        const fetchBlockContent = async () => {
            try {
                const response = await getContentBlocks(TOKEN, activeBlock?.blockURLId, courseURLId);
                return response.data || null
            } catch (e) {
                console.error(e)
            }
        };

        if (activeBlock) fetchBlockContent().then((data) => setCurrentBlockContent(data))
    }, [activeBlock?.blockURLId, activeBlock?.blockId]);

    useEffect(() => {
        const fetchBlockFiles = async () => {
            const response = await getFilesBlocks(TOKEN, activeBlock?.blockURLId, courseURLId);
            if (!Array.isArray(response.data)) {
                setCurrentBlockFiles(null);
            } else {
                setCurrentBlockFiles(response.data);
            }
        };

        if (activeBlock) {
            fetchBlockFiles();
        }
    }, [activeBlock]);

    useEffect(() => {
        if (activeBlock) {
            const fetchLongreads = async () => {
                const response = await getLongreads(TOKEN, activeBlock?.blockURLId, courseURLId);
                if (!Array.isArray(response.data)) {
                    setCurrentLongreads(null);
                } else {
                    setCurrentLongreads(response.data);
                }
            };
            fetchLongreads();
        }
    }, [activeBlock]);

    useEffect(() => {
        if (allBlocks) setActiveBlock(allBlocks[0]);
    }, [allBlocks]);

    const fixLinkForFile = (link: string) => {
        if (!link) return;
        const meow = link.slice(0, 5);
        if (meow === "https") {
            return link;
        } else {
            return `${API}/" + link`
        }
    };

    return (
        <>
            <div className="course-player-main-container">
                <div className="blocks-container">
                    <div>{activeBlock?.courseName}</div>
                    {
                        allBlocks?.map((element: any) => {
                            return (
                                <p
                                    onClick={() => selectBlock(element)}
                                    className={element["blockURLId"] === activeBlock?.blockURLId ? "block-purchased active-block" : "block-purchased"}
                                    key={element["blockId"]}
                                >
                                    {element["blockName"]}
                                </p>
                            );
                        })
                    }
                </div>
                <div className="content-container">
                    {
                        currentBlockContent ? (
                            <iframe
                                className="iframe-player"
                                src={`${currentBlockContent[currentLecture]}`}
                                width="5600"
                                height="3150"
                                allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
                            ></iframe>
                        ) : (
                            <div className="no-video-block">
                                <p>Записи этого блока пока нет, она появится позже</p>
                            </div>
                        )
                    }

                    <div className="button-container">
                        {
                            currentBlockContent &&
                            currentBlockContent.map((button: any, index: any) => {
                                return (
                                    <div
                                        onClick={() => setCurrentLecture(index)}
                                        key={button}
                                        className={
                                            index === currentLecture
                                                ? "switch-video-button active-video-button"
                                                : "switch-video-button"
                                        }
                                    >
                                        {index + 1}
                                    </div>
                                );
                            })
                        }
                    </div>

                    {
                        currentBlockFiles && (
                            <div className="files-container">
                                <h4>Дополнительные материалы к блоку</h4>
                                <div className="files-row">
                                    {currentBlockFiles.map((element: any, index: any) => {
                                        return (
                                            <a
                                                target="_blank"
                                                href={fixLinkForFile(element.srcMaterialInfo)}
                                                className="file"
                                            >
                                                <AiFillFile color="#6565ee" className="file-icon"/>
                                                {/* <p>{index + 1}</p> */}
                                                <p>{element.materialName}</p>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    }

                    {
                        currentLongreads && (
                            <div className="files-container">
                                <h4>Лонгриды по теме</h4>
                                <div className="longreads-container-course">
                                    {currentLongreads.map((element: any, index: any) => {
                                        console.log(element.contentUrlid);
                                        return (
                                            <>
                                                <LongreadCard
                                                    to={`../../longreads/${extractNumbersFromLink(
                                                        element.contentUrlid
                                                    )}`}
                                                    name={element.contentTitle}
                                                    img={
                                                        //@ts-ignore

                                                        LONGREADS[
                                                            extractNumbersFromLink(element.contentUrlid)
                                                            ].background_image
                                                    }
                                                    descr={
                                                        //@ts-ignore
                                                        LONGREADS[
                                                            extractNumbersFromLink(element.contentUrlid)
                                                            ].article_descr
                                                    }
                                                />
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default CoursePlayer;
