import React from 'react';
import {fixImgSrc} from "../service/api/coursesApi";
import {FaChevronRight} from "react-icons/fa";
import {Link} from "react-router-dom";

const CourseCard = (props: any) => {
    const element = props.element

    if (element.courseId === 13) return null

    const handleCourceId = (e: any) => {
        if (!props.setCourceId) return
        e.preventDefault()
        props.setCourceId(element.courseUrlid)
    }

    return (
        <Link
            onClick={handleCourceId}
            to={`/courses/${element.courseUrlid}`}
            className="course"
            key={element.courseId}
        >
            <div className={"course-wrapper"}>
                <div className="course-img"
                     style={{backgroundImage: `url(${fixImgSrc(element.srcPicturePage)})`}}
                >
                    {
                        (element.currentPrice || element.oldPrice) && !props.setCourceId ?
                            <div className={"course-price"}>
                                <div>
                                    <div className={"course-price-current"}>{element.currentPrice}₽</div>
                                    <span className={"course-price-old"}>{element.oldPrice}₽</span>
                                </div>
                                <div className={"discount"}>
                                    -{Math.round(((1 - element.currentPrice / element.oldPrice) * 100))}%
                                </div>
                            </div> : null
                    }
                </div>
                <div className={"course-inner"}>
                    <h2 className="course-name">{element.courseName}</h2>
                   <p className="course-description">{element.description}</p>
                    <div className={"course-button"} style={{color: "#000"}}>
                        <button>к курсу</button>
                        <FaChevronRight style={{marginLeft: 3, fontSize: "0.8em"}}/>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CourseCard;
