import React from "react";
import "../../styles/componentStyles/docs.css";

function Oferta() {
    const htmlraw = (`
    <div>
    <p style="text-align: center;"><span style="font-weight: 400;">Оферта</span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">Редакция от 18 октября 2024 года</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Настоящий документ, постоянно размещенный в сети Интернет по сетевому адресу: https://nysacademy.ru/oferta, является публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации на заключение договора (далее - Договор) с любым заинтересованным физическим лицом (далее - Заказчик). Надлежащим акцептом настоящей оферты в соответствии со статьей 438 Гражданского кодекса Российской Федерации является совершение Заказчиком в совокупности следующих действий:</span></p>
<p><span style="font-weight: 400;">- указание адреса электронной почты и/или имени при оформлении заказа в специальной форме на сайте, нажатие кнопки &laquo;Оформить заказ&raquo; при покупке Курса на Сайте или доступа к Сообществу;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- оплата Заказчиком стоимости Курса или доступа к Сообществу, указанной Исполнителем в момент покупки.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Оплачивая стоимость Курса или Доступа, Заказчик:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- гарантирует достоверность и актуальность сведений, предоставляемых о себе;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- гарантирует, что он является совершеннолетним и полностью дееспособным лицом;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- соглашается, что он самостоятельно несет ответственность за любые последствия, возникающие в результате указания недостоверных, неактуальных или неполных сведений о себе.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Датой акцепта оферты Заказчиком (датой заключения Договора) считается дата зачисления денежных средств за оплату Исполнителем Курса или Доступа на расчетный счет Исполнителя.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Исполнителем по Договору является:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Индивидуальный предприниматель Зверев Никита Сергеевич</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">ОГРН 322784700233364</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">ИНН 781131547487</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Рс 40802810300003590498</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">БИК 044525974</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">АО &laquo;ТИНЬКОФФ БАНК&raquo;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Кор. счет 30101810145250000974</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Адрес: Москва, 127287, ул. Хуторская 2-я, д. 38А, стр 26</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">1.Термины и определения</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">В Договоре, если иное прямо не вытекает из текста, указанные ниже термины будут иметь следующие значения:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Сайт</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Составное произведение, представляющее собой совокупность информации, текстов, графических элементов, дизайна, изображений, фото - и видеоматериалов, иных результатов интеллектуальной деятельности, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет под доменными именами: https://nysacademy.ru.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Личный кабинет</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Совокупность защищенных страниц Сайта, созданных в результате регистрации Заказчика и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте. Юридически значимые действия, совершенные Заказчиком через его Личный Кабинет являются совершенными с его простой электронной подписью, где идентификатором и ключом электронной подписи является его аутентификационные данные.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Сообщество</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Закрытый телеграм-канал под названием &laquo;Новая Академия&raquo;, в котором на регулярной основе публикуются авторские информационно-развлекательные материалы. Доступ к каналу оплачивается согласно опубликованным на Сайте тарифам.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Курс</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Определенная часть информации под названиями (перечень наименований не ограничен и может быть изменен Исполнителем): &laquo;Курс&raquo;, &laquo;Практический курс&raquo;, &laquo;Профессиональный курс&raquo;, &laquo;Видеокурс&raquo;, &laquo;Экспресс-курс&raquo;, &laquo;Видеолекция&raquo;, содержащийся на Сайте, доступ к которой передается путем предоставления Заказчику определенных данных и команд, состоящая из совокупности взаимосвязанных лекций и материалов (тексты, фото- и видеоматериалы, иные объекты интеллектуальных прав), объединенных единой темой, расположенных в определенной последовательности и направленных на самостоятельное приобретение Заказчиком знаний и навыков по соответствующей теме, а также очные мероприятия (лекции, образовательные курсы и т. п.), проводимые Исполнителем.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Контент</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Объекты авторского права, размещенные на Сайте.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Подписка</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Предоставленное Пользователю право доступа к Сообществу и контенту за определенную плату и/или на определенных условиях и в течение определенного времени на условиях, указанных в описании тарифов на сайте nysacademy.ru.&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">2.Предмет Договора</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">2.1. Исполнитель оказывает Заказчику услуги дополнительного образования, в соответствии с основным видом деятельности 85.41.2 &laquo;Образование в области культуры&laquo;, (рег. номер записи в Едином государственном реестре индивидуальных предпринимателей) и обязуется предоставить Заказчику через информационно-коммуникационную сеть &laquo;Интернет&raquo; доступ к Курсу или к Сообществу, а Заказчик обязуется уплатить Исполнителю вознаграждение за вышеуказанные услуги и предоставленный доступ.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Исполнитель предоставляет Заказчику доступ только к той части Сайта (данным и командам, необходимым для доступа к Курсу или Сообществу), которая соответствует тарифу, выбранному Заказчиком.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">2.2. По дополнительному согласованию с Исполнителем, доступ может быть предоставлен третьему лицу, на которое укажет Заказчик, при условии несения ответственности за его действия, как за свои собственные. В этом случае, в рамках исполнения Договора третье лицо приравнивается к Заказчику и имеет все те же права и обязанности что и Заказчик, если иное прямо не указано в Договоре.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">2.3. Исполнитель размещает перечень тарифов, доступных для приобретения, на Сайте. Информация о стоимости, содержании тарифа доступна Заказчику на Сайте.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">2.4. Обязательства Исполнителя по Договору считаются исполненными с момента предоставления Заказчику доступа к Курсу или Сообществу.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">3.Правила предоставления доступа к Курсу и Сообществу</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">3.1 Под предоставлением доступа к Курсу и Сообществу имеется в виду предоставление доступа к определенной совокупности данных и команд, позволяющих интерактивно взаимодействовать с определенным Контентом.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">3.2 Исполнитель предоставляет Заказчику доступ к Курсу и Сообществу после оплаты стоимости Курса в порядке, предусмотренном разделом 5 Договора.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">3.3 Исполнитель информирует Заказчика о предоставлении доступа к Курсу и Сообществу путем направления соответствующего уведомления и/или логина и пароля от Личного кабинета на электронную почту Заказчика, указанную им при регистрации на Сайте при оплате тарифов &laquo;Продвинутый&laquo; и &laquo;Абсолютный&laquo;.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.Права и обязанности Сторон</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.1. Исполнитель обязан:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.1.1 Осуществлять информационную поддержку Заказчика ежедневно с 10:30 до 22:30 по московскому времени. При этом Исполнитель оставляет за собой право изменять временной интервал и режим осуществления информационной поддержки. Все вопросы по поддержке направляются Исполнителю по электронному почтовому адресу: (info@nysacademy.ru)</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.2. Исполнитель вправе:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.2.1. Без согласования с Заказчиком привлекать третьих лиц для исполнения настоящего Договора, оставаясь ответственным за действия таких лиц, как за свои собственные.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.2.2. Запрашивать у Заказчика всю необходимую информацию, документы для надлежащего исполнения обязательств по настоящему Договору.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.2.3. Изменять стоимость Курса или доступа к Сообществу. Информация об актуальной стоимости тарифов доступна Заказчику на сайте.&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.2.4.Приостанавливать работу сайта https://nysacademy.ru для проведения необходимых плановых профилактических и ремонтных работ на технических ресурсах Исполнителя.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.2.5. Изменять содержание Курса, включая темы отдельных лекций, их содержание.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.3. Заказчик обязан:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.3.1. Своевременно и в полном объеме оплатить стоимость Курса или доступа к Сообществу.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.3.2. Своевременно предоставлять полную и достоверную информацию, необходимую для продажи Курса или доступа к Сообществу (в том числе при регистрации на Сайте).</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">4.3.3. Обеспечить конфиденциальность логина и пароля к личному кабинету на Сайте.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<ol start="5">
<li><span style="font-weight: 400;"> Финансовые условия.</span></li>
</ol>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">5.1. Стоимость тарифов указывается на Сайте.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">5.2. Стоимость услуг Исполнителя не облагается НДС на основании пп. 26 п. 2 ст. 149 Налогового Кодекса РФ.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">5.3. Заказчик производит оплату в размере 100% (ста процентов) стоимости тарифа единовременно или в рассрочку в соответствии с условиями, установленными Исполнителем, в отношении определенного тарифа при условии, что такая возможность предусмотрена Исполнителем</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">5.4. Датой исполнения Заказчиком обязательств по Договору является дата поступления денежных средств на расчетный счет Исполнителя.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">5.5. Исполнитель не контролирует аппаратно-программный комплекс электронной системы платежей. Если в результате таких ошибок произошло списание денежных средств Заказчика, но платеж не был авторизован электронной системой платежей, обязанности по возврату денежных средств Заказчику лежат на провайдере электронной системы платежей.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">5.6. Фискальные документы отправляются в электронном виде на почту Заказчика, указанную при регистрации на сайте в соответствии с законодательством РФ.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">6.Интеллектуальная собственность</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">6.1. Исключительное право на Сайт, в том числе на его части принадлежат Исполнителю, либо им получены все необходимые права и разрешения.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">6.2. Заказчик имеет право пользоваться Сайтом в рамках предоставленного функционала и интерактивного взаимодействия с доступной информацией на все время доступа к Сайту в соответствии с настоящим Договором.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">6.3. Заказчик обязан:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">воздерживаться от любых действий, которые нарушают права Исполнителя на результаты интеллектуальной деятельности, в частности, не копировать, не записывать, не воспроизводить, не распространять любые результаты интеллектуальной деятельности Исполнителя без письменного разрешения Исполнителя;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">не предоставлять свои аутентификационные данные для доступа в Личный кабинет на Сайте третьим лицам. В случае утраты, а также в случаях незаконного получения доступа к логину и паролю третьими лицами, Заказчик обязуется незамедлительно сообщить об этом Исполнителю, путем направления уведомления по адресу: info@nysacademy.ru. До момента отправки указанного извещения все действия, совершенные с использованием Личного кабинета Заказчика, считаются совершенными Заказчиком.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">6.4. Использование Заказчиком Контента Сайта, его содержимого и составляющих (как в целом, так и фрагментарно) и прочих разработанных Исполнителем технических решений не означает передачи (отчуждения) Заказчику и / или любому третьему лицу прав на результаты интеллектуальной деятельности, как в целом, так и в части.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">6.5. К конфиденциальной относится любая информация, опубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения, ставшая известной Заказчику в рамках исполнения Сторонами обязательств по Договору. Заказчик обязуется не разглашать конфиденциальную информацию третьим лицам без предварительного письменного согласия Исполнителя.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">7.Ответственность исполнителя</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">7.1. Исполнитель не несёт ответственности за качество соединения с сетью Интернет и функционирование оборудования и программного обеспечения Заказчика.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">7.2. При неисполнении Заказчиком обязательства, предусмотренного пунктами 6.3., 6.5. Договора, и выявлении Исполнителем факта доступа третьих лиц к содержанию Курса или Сообщества Заказчик обязан во внесудебном порядке по письменному требованию Исполнителя оплатить штраф в размере 100000 (ста тысяч) рублей за каждый случай несанкционированного предоставления доступа третьим лицам.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">7.3. В случае нарушения Заказчиком условий Договора Исполнитель вправе прекратить доступ Заказчика к Личному кабинету, а также заблокировать доступ Заказчика к Курсу(-ам) и Сообществу.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">7.4. Исполнитель не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, а также за возможный ущерб, возникший в результате:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- сбоев в работе Сайта и (или) иного программного обеспечения, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- отсутствия (невозможности установления, прекращения и пр.) Интернет-соединений;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение настоящего Договора;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- других случаев, связанных с действием (бездействием) пользователей Интернета и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">- использования (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком выбранной им формы оплаты услуг по Договору.</span></p>
<p style="text-align: center;"><br /><br /></p>
<p><span style="font-weight: 400;">8.Срок действия Договора. Порядок расторжения</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">8.1. Договор вступает в силу с даты его акцепта Заказчиком.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">8.2. Договор может быть в любое время расторгнут по инициативе Исполнителя в одностороннем порядке.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">8.3. Договор может быть расторгнут в части доступа к Курсам или Сообществу по инициативе Исполнителя в одностороннем внесудебном порядке в случае отсутствия организационной, технической или юридической возможности предоставлять доступ к Курсам или Сообществу.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">9.Изменение условий Договора</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">9.1. Исполнитель вправе в одностороннем порядке изменять условия Договора, и такие изменения вступают в силу в момент опубликования новой версии Договора в сети Интернет по адресу https://nysacademy.ru/oferta. При этом в части величины Вознаграждения за уже предоставленный доступ старая версия Договора продолжает действовать для Сторон без изменений. В остальной части новые положения Договора имеют обратную силу.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">9.2. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">9.3. Исполнитель вправе уступать права, переводить долги (в том числе привлекать субагентов и субподрядчиков) по всем обязательствам, возникшим из Договора. Настоящим заказчик дает свое согласие на уступку прав и перевод долга любым третьим лицам. О состоявшейся уступке прав и/или переводе долга Исполнитель информирует Заказчика, размещая соответствующую информацию в Личном кабинете.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">10.Персональные данные</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">10.1. Заказчик дает свое согласие Исполнителю на обработку персональных данных Заказчика, указанных им при регистрации на Сайте, а также в Личном кабинете на условиях, предусмотренных Политикой обработки персональных данных, размещенной на сайте https://nysacademy.ru.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">10.2. Заказчик дает согласие на использование Исполнителем отзывов Заказчика об Исполнителе и оказываемых им услугах, оставленных Заказчиком в официальных группах Исполнителя в социальных сетях, в целях размещения таких отзывов на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя. Настоящее согласие действует с даты заключения Договора. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">10.3. Заказчик дает свое согласие на получение рекламно-информационного материала от Исполнителя, в том числе в целях возможного получения различных поощрений (бонусы, скидки). Условия получения и размер поощрения определяется Исполнителем самостоятельно. В случае изменения условий поощрения Исполнитель вправе изменять, добавлять и убирать бонусы, направляя письменное уведомление Заказчику на электронную почту, указанному в Личном кабинете. В случае таких изменений Заказчик не вправе требовать от Исполнителя оставлять прежние условия поощрений, не вправе требовать какие-либо выплаты и убытки, связанные с такими изменениями. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">10.4. Заказчик дает свое согласие Исполнителю на обнародование и дальнейшее использование изображения Заказчика в фото-, видеоматериалах, равно как и зафиксированного в независимых друг от друга кадрах таких видеоматериалов, а также зафиксированного в любых иных объектах изображении в целях размещения такого изображения на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя и любых иных целях, связанных с деятельностью Исполнителя и не противоречащих действующему законодательству. Настоящее согласие действует с даты заключения Договора и распространяется на любые объекты, созданные Исполнителем в период доступа к Платформе Заказчиком, а также полученные от Заказчика в этот период. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления Исполнителю.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">11.Порядок разрешения споров</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">11.1. В случае возникновения любых разногласий между Заказчиком и Исполнителем относительно исполнения каждой из сторон условий Договора, а также любых иных разногласий, такие разногласия должны быть урегулированы с применением обязательного досудебного претензионного порядка. Исполнитель обязуется направить Заказчику претензию в электронном виде на адрес электронной почты, указанный Заказчиком при регистрации на Сайте. Заказчик обязуется направить Исполнителю претензию в электронном виде на адрес электронной почты info@nysacademy.ru, а также продублировать в претензию в письменном виде на адрес Исполнителя, указанный в разделе 15 Договора. Срок ответа на претензию - 10 (десять) рабочих дней со дня ее получения. Если Законодательством РФ установлен меньший срок, то применяется срок, установленный законодательством. При несоблюдении любой из сторон, всех перечисленных выше условий обязательный претензионный порядок не считается соблюденным.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">12.Уведомления и электронный документооборот</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">12.1. Если иное не предусмотрено в Договоре или действующим законодательством, любые уведомления, запросы или иные сообщения (корреспонденция), представляемые Сторонами друг другу, должны быть оформлены в письменном виде и направлены получающей Стороне по почте, путем направления заказной корреспонденции, по электронной почте (на адрес и (или) с адреса Исполнителя &ndash; адреса электронной почты в пределах доменной зоны https://nysacademy.ru или при помощи курьерской службы. Датой получения корреспонденции считается дата получения уведомления о доставке почтового отправления, в том числе заказной корреспонденции, электронного подтверждения доставки при отправлении электронной почтой (или в отсутствии такового &ndash; момент отправления сообщения), или день доставки в случае отправления корреспонденции с курьером. При рассмотрении споров в суде переписка Сторон по электронной почте, а также переписка через Личный кабинет будут признаны Сторонами достаточными доказательствами.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">12.2. При исполнении (изменении, дополнении, прекращении) Договора, а также при ведении переписки по указанным вопросам допускается использование аналогов собственноручной подписи Сторон. Стороны подтверждают, что все уведомления, сообщения, соглашения и документы в рамках исполнения Сторонами обязательств, возникших из Договора, подписанные аналогами собственноручной подписи Сторон, имеют юридическую силу и обязательны для исполнения Сторонами. Под аналогами собственноручной подписи понимаются уполномоченные адреса электронной почты и учетные данные к Личному кабинету.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">12.3. Стороны признают, что все уведомления, сообщения, соглашения, документы и письма, направленные с использованием уполномоченных адресов электронной почты и Личного кабинета, считаются направленными и подписанными Сторонами, кроме случаев, когда в таких письмах прямо не указано обратное.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">12.4. Уполномоченными адресами электронной почты Сторон признаются:</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Для Исполнителя: info@nysacademy.ru или иной в пределах доменной зоны https://nysacademy.ru. Для Заказчика: адрес электронной почты, указанный при регистрации на Сайте.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">12.5. Стороны обязуются обеспечивать конфиденциальность сведений и информации, необходимых для доступа к уполномоченным адресам электронной почты и Личному кабинету, не допускать разглашение такой информации и передачу третьим лицам. Стороны самостоятельно определяют порядок ограничения доступа к такой информации.</span></p>
<p style="text-align: center;"><br /><br /></p>
<ol start="13">
<li><span style="font-weight: 400;"> Подписка</span></li>
</ol>
<p><span style="font-weight: 400;">13.1. Стоимость предоставления Пользователю прав доступа Сообществу по Подписке указана по адресу nysacademy.ru в разделе &laquo;Тарифы&raquo; и различается в зависимости от тарифа Подписки.</span></p>
<p><span style="font-weight: 400;">13.2. Порядок оплаты и возврата Подписки регулируется ст. 429.4 ГК РФ в связи с тем, что Соглашение является абонентским договором.</span></p>
<p><span style="font-weight: 400;">13.3. Пользователь оплачивает Подписку на условиях предоплаты каждый расчетный период. Дату истечения действия Подписки указывается в телеграм-боте &laquo;@ManagerSubscribes_bot&raquo; по кодовому словосочетания &laquo;Статус подписки&raquo;. Денежные средства за новый период списываются автоматически за 24 часа до указанной даты истечения действия Подписки. Пользователь обязан самостоятельно контролировать сроки оплаты.</span></p>
<p><span style="font-weight: 400;">13.4. Период оформленной Подписки исчисляется с момента оплаты Пользователем в полном объеме Подписки в соответствии с условиями настоящего соглашения на выбранный им период Подписки и предоставления Пользователю доступа к возможности доступа к Сообществу по соответствующей Подписке.</span></p>
<p><span style="font-weight: 400;">13.5. Платежные системы Исполнитель выбирает и устанавливает самостоятельно по своему усмотрению и может изменять в любое время без отдельно направляемого Пользователю уведомления.</span></p>
<p><span style="font-weight: 400;">13.6. Пользователь настоящим подтверждает, что уведомлен о том, что внесение им денежных средств в оплату Подписки содержащей данные о банковской карте Пользователя осуществляется через платежные системы третьих лиц.</span></p>
<p><span style="font-weight: 400;">13.7. Для осуществления проверки подлинности данных банковской карты, указанных Пользователем в целях оформления Подписки, возможно резервирование банком-эмитентом денежных средств, находящихся на соответствующих банковских счетах Пользователя в банке, на сумму, не превышающую 60 (шестьдесят) рублей (верификация).</span></p>
<p><span style="font-weight: 400;">13.8. После успешного прохождения проверки подлинности данных банковской карты банком-эмитентом банковская карта Пользователя считается привязанной. Разблокировка суммы, зарезервированной при проверке подлинности банковской карты, производится в сроки, определяемые банком-эмитентом и не зависит от Исполнителя.</span></p>
<p><span style="font-weight: 400;">Исполнитель оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных привязанной карты.</span></p>
<p><span style="font-weight: 400;">Привязывая банковскую карту в соответствии с соглашением, Пользователь полностью соглашается с применением технологии проведения платежей, при которой данные привязанной карты сохраняются на стороне платежных провайдеров и используются в дальнейшем для проведения платежей без авторизации через 3-D Secure и без ввода CVV/CVC.</span></p>
<p><span style="font-weight: 400;">13.9. В случае утраты/замены банковской карты и при намерении продолжения пользования Подпиской Пользователь обязуется пройти верификацию новой банковской карты.</span></p>
<p><span style="font-weight: 400;">13.10. Комиссия оператора платежей, а также иных лиц, вовлеченных в процесс оплаты Подписки, оплачивается Пользователем самостоятельно и за свой счет.</span></p>
<p><span style="font-weight: 400;">13.11. Все платежи, осуществляемые Пользователем в адрес Исполнителя, не включают в себя стоимость Интернета или трафика данных. Интернет-провайдер или оператор мобильной связи Пользователя может взимать плату за объем трафика данных по своим тарифам.</span></p>
<p><span style="font-weight: 400;">13.12. После внесения Пользователем денежных средств в счет оплаты Подписки, активация Подписки в личном кабинете Пользователя может происходить с задержкой, что связано с техническими особенностями в работе сервиса при взаимодействии с компанией, являющейся платежной системой.</span></p>
<p><span style="font-weight: 400;">13.13. Стороны пришли к соглашению об отказе от оформления счетов-фактур, Исполнитель не оформляет и не выставляет в адрес Пользователя счета-фактуры.</span></p>
<p><span style="font-weight: 400;">13.14. При оплате Подписки Пользователь соглашается и полностью осознает условия настоящего соглашения, полностью их принимает. Пользователь обязуется ознакомиться с Контентом, доступным к просмотру после оформления каждой из Подписок, совершение оплаты Подписки Пользователем автоматически означает, что Пользователь ознакомился и согласен с перечнем Контента, доступного по соответствующей Подписке, и не имеет претензий к полноте и содержанию Контента в Подписке.</span></p>
<p><span style="font-weight: 400;">13.15. Стоимость Подписки может быть в одностороннем порядке изменена Исполнителем в любое время по своему усмотрению без предварительного уведомления Пользователя. При этом при Автопродлении Подписки с привязанной Пользователем банковской карты будут списываться денежные средства в размере стоимости Подписки на момент её первого подключения.</span></p>
<p><span style="font-weight: 400;">13.16. Исполнитель вправе отменить Подписку, приобретенную Пользователем, если оформление/начисление/покупка такой Подписки произошло и/или было возможно в результате ошибок в работе Сервиса. В случае отмены Подписки на основании настоящего пункта Общество возвращает Пользователю денежные средства, списанные с банковской карты Пользователя в оплату отмененной Подписки (при наличии списания). Денежные средства возвращаются Исполнителем на реквизиты банковской карты Пользователя, с которой была осуществлена оплата отмененной в последующем Подписки. Исполнитель не несет ответственности за время зачисления возвращенной суммы денежных средств на банковский счет Пользователя, в связи с тем, что ответственность за зачисление денежных средств лежит в сфере ответственности банка, обслуживающего Пользователя.</span></p>
<p><span style="font-weight: 400;">13.17. Исполнитель вправе отменить Подписку, приобретенную Пользователем, в случае нарушения последним условий настоящего соглашения.</span></p>
<p style="text-align: center;">&nbsp;</p>
<ol start="14">
<li><span style="font-weight: 400;"> Автопродление</span></li>
</ol>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">14.1. При оформлении Пользователем Подписки, в том числе с использованием Промокода, скидки, Пробного периода автоматически активируется Автопродление Подписки, при этом за следующий период Подписки будет произведено списание в объеме общей стоимости Подписки на следующий период, в соответствии со стоимостью Подписки, указанной в разделе &laquo;Тарифы&raquo; на странице nysacademy.ru.</span></p>
<p><span style="font-weight: 400;">14.2. Автопродление Подписки осуществляется посредством автоматического списания денежных средств с банковского счета Пользователя согласно правилам и условиям банка и/или платежной системы, при условии наличия денежных средств на таком счете.</span></p>
<p><span style="font-weight: 400;">14.3. Если в последний день оплаченного срока Подписки на привязанной к учетной записи Пользователя банковской карте недостаточно денежных средств для оплаты следующего срока Подписки, то Общество вправе приостановить Подписку со дня, следующего за последним днем оплаченного срока.</span></p>
<p><span style="font-weight: 400;">14.4. Пользователь вправе в любой момент отказаться от Автоматического продления Подписки в телеграм-боте &laquo;@ManagerSubscribes_bot&raquo;, введя кодовое слово &laquo;Отмена подписки&raquo;. В таком случае Подписка предоставляется до конца оплаченного расчетного периода. Денежные средства за новый расчетный период не взимаются. Отказ от автоматического продления Подписки на следующий расчетный период возможен не позднее чем за 24 часа до окончания текущего расчетного периода.</span></p>
<p><span style="font-weight: 400;">14.5. Удаление Личного кабинета Пользователя, а также отсутствие со стороны Пользователя действий по использованию доступа к Контенту не являются надлежащим прекращением абонентского договора и отказом от автоматического продления Подписки, а также основанием для возврата денежных средств..</span></p>
<p><span style="font-weight: 400;">14.6. В случае отсутствия средств на банковском счете Пользователя на момент осуществления автоматического списания, автоматическое списание может быть повторено до момента успешного списания средств в срок до 60 (шестидесяти) календарных дней с момента первой попытки такого автоматического списания.</span></p>
<p style="text-align: center;"><br /><br /></p>
<p><span style="font-weight: 400;">15.Прочие условия</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">15.1. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне при условии сохранения доступа к Курсам или Сообществу только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">15.2. Недействительность одного из условий договора не влечет недействительности всего Договора в целом. В случае признания условия Договора недействительным, стороны обязаны вступить в переговоры и изменить договор таким образом, чтобы он продолжил свое действие.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">15.3. Во всем остальном, что не урегулировано Договором, Стороны руководствуются действующим законодательством Российской Федерации.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Приложение №1 к Публичной оферте.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Правила возврата денежных средств.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">Настоящие Правила возврата денежных средств (далее - Правила) регулируют отношения между Исполнителем и Заказчиком, связанные с возвратом оплаченной стоимости тарифа в случае досрочного расторжения Договора.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">1.Возврат денежных средств возможен до исполнения сторонами обязательств по Договору, то есть до момента предоставления доступа к Курсам или к Сообществу. В момент оплаты Курсов или доступа к Сообществу и предоставления доступа к Курсу или Сообществу, обязательства сторон по договору считаются исполненными в полном объеме, надлежащим образом.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">2.Курсы входят в перечень непродовольственных товаров надлежащего качества, не подлежащих обмену в соответствии с Постановлением Правительства РФ от 31.12.2020 N 2463 &laquo;Об утверждении Правил продажи товаров по договору розничной купли-продажи, перечня товаров длительного пользования, на которые не распространяется требование потребителя о безвозмездном предоставлении ему товара, обладающего этими же основными потребительскими свойствами, на период ремонта или замены такого товара, и перечня непродовольственных товаров надлежащего качества, не подлежащих обмену, а также о внесении изменений в некоторые акты Правительства Российской Федерации&laquo;.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p><span style="font-weight: 400;">3.В случае отказа Заказчика от Договора и наличия у него права на возврат денежных средств в соответствии с п. 1 Правил, Исполнитель направляет Заказчику соответствующее заявление об отказе от исполнения Договора и возврате денежных средств на адрес на электронный адрес info@nysacademy.ru. Исполнитель в течение пяти рабочих дней рассматривает указанное заявление и в случае его правомерности, в течение пяти рабочих дней с даты принятия положительного решения, возвращает уплаченные Исполнителем денежные средства.</span></p>
<p style="text-align: center;">&nbsp;</p>
</div>
	`);

    return (
        <div
            dangerouslySetInnerHTML={{__html: htmlraw}}
            className="docs"
            style={{paddingRight: "5rem", paddingLeft: "5rem"}}
        ></div>
    );
}

export default Oferta;
