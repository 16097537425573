import React from 'react';
import Carousel from "react-multi-carousel";
import CourseCard from "../../stories/CourseCard";

export const CarouselCources = (props) => {
    const {data}= props

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 600},
            items: 2
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items:1
        }
    };


    return (
        <div style={{marginTop: "1rem"}}>
            {
                data?.length > 0 ?
                    <Carousel responsive={responsive}>
                        {data.map((element) =>
                            <div style={{height: "100%", padding: "12px 16px"}}>
                                <CourseCard setCourceId={props.setCourceId} element={element}/>
                            </div>
                        )}
                    </Carousel> :
                    <p>У Вас пока нет купленных курсов или подкастов.</p>
            }
        </div>
    );
};
