import "./Modal.css"
import React, {ReactNode, useEffect} from 'react';
import { Portal } from '../portal/Portal';
import {classNames} from "../../service/functions";

interface ModalProps {
  className?: string;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const Modal = (props: ModalProps) => {
  const { className = "", children, isOpen, onClose } = props;

  const modes: Record<string, boolean> = {
    ["open"]: isOpen,
  };

  // @ts-ignore
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';
  }, [isOpen]);

  const handleClose = (e: React.MouseEvent) => e.stopPropagation();

  if (!isOpen) return null

  return (
    <Portal>
      <div className={classNames("modal", modes, [className])}>
        <div className={classNames("overlay")} onClick={onClose}>
          <div className={classNames("content")} onClick={handleClose}>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
