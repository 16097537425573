import React, {useContext, useEffect, useState} from "react";
import Mrgn from "../../layout/Mrgn";
import useGetUser from "../../service/hooks/useGetUser";
import AuthContext from "../../service/context/AuthProvider";
import {getBoughtCourses, getPurchasedBlocks} from "../../service/api/coursesApi";
import "../../styles/componentStyles/account/account.css";
import Footer from "../../layout/Footer";
import {generateReferLink, getStatus} from "../../service/api/authApi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CourseCard from "../../stories/CourseCard";
import CoursePlayer from "../course/CoursePlayer";
import axios, {AxiosResponse} from "axios";
import Button from "../../components/button/Button";
import {Link} from "react-router-dom";

function Account() {
    const [courceId, setCourceId] = useState("")
    const [refer, setRefer] = useState<null | string>(null);
    const token = localStorage.getItem("token")
    const {auth, setAuth}: any = useContext(AuthContext); // using context

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("refer");
        setAuth({});
    };

    const [user] = useGetUser();

    const [data, setBoughtCourses] = useState<any>([]);
    const [blocks, setBlocks] = useState<any>([])


    useEffect(() => {
        const fetchBoughtCourses = async () => {
            try {
                const response = await getBoughtCourses();
                if (response?.status === 200) {
                    setBoughtCourses(response.data);
                }
            } catch (e) {
                console.log(e)
            }
        };

        fetchBoughtCourses();
    }, []);

    useEffect(() => {
        const fetchRefer = async () => {
            const response = await generateReferLink();
            if (response.status === 200) {
                setRefer(response.data);
            }
        };
        fetchRefer();
    }, []);

    useEffect(() => {
        try {
            if (courceId) getPurchasedBlocks(token, courceId)
                .then((response: AxiosResponse) => {
                    if (response.status === 200) setBlocks(response.data);
                    else setBlocks([]);
                });
        } catch (e) {
            console.log(e);
        }
    }, [courceId]);

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <>
            <Mrgn/>
            <div className="account-container">
                <div className="account-up-bar">
                    <h2 className="account-name">{user && user?.email}</h2>
                    <div className="account-exit" onClick={logout}>
                        <img src="../../exit.png" alt=""/>
                        <p>Выйти</p>
                    </div>
                </div>

                <div style={{marginTop: "1rem"}}>

                    {
                        data?.length > 0 ?
                            <Carousel responsive={responsive}>
                                {data.map((element: any) =>
                                    <div style={{height: "100%", padding: "12px 16px"}}>
                                        <CourseCard setCourceId={setCourceId} element={element}/>
                                    </div>
                                )}
                            </Carousel> :
                            <p>У Вас пока нет купленных курсов или подкастов.</p>
                    }
                </div>
            </div>

            {blocks?.length > 0 ? <CoursePlayer purchasedBlocks={blocks} courseURLId={courceId}/> : null}

            <div style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
                <Link to={"https://t.me/ManagerSubscribes_bot"} target={"_blank"}>
                    <Button style={{width:"auto"}} text={" Управление подпиской"}/>
                </Link>
            </div>

            <Footer/>
        </>
    );
}

export default Account;
