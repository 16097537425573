import axios from "axios";
import {API, CONTENT_API, COURSES_API, USER_API} from "../const/api";

const coursesApi = axios.create({
    baseURL: COURSES_API,
});

const userCoursesApi = axios.create({
    baseURL: USER_API,
});

const contentCoursesApi = axios.create({
    baseURL: CONTENT_API,
});

export const getCoursesPage = async () => {
    let token = localStorage.getItem("token");
    const wtoken = () => {
        if (token) {
            return `?token=${token}`;
        } else {
            return "";
        }
    };
    return await coursesApi.get(`/mainpage${wtoken()}`).catch(error => {
        return error?.response?.status ?? 500
    });
};

export const fixImgSrc = (imgUrl: string) => {
    return API + "/" + imgUrl;
};

export const getCourseInfo = async (courseURL: string) => {
    let token = localStorage.getItem("token");
    const wtoken = () => {
        if (token) {
            return `&token=${token}`;
        } else {
            return "";
        }
    };
    return await coursesApi.get(
        `/coursePage?CourseURL=${courseURL}${wtoken()}`
    );
};

export const getBoughtCourses = async () => {
    return await userCoursesApi.get(
        `/PersonalArea?token=${localStorage.getItem("token")}`
    ).catch(error => console.log(error));
};

export const getPurchasedBlocks = async (
    token: string | null,
    courseURLId: string
) => {
    return await userCoursesApi.get(
        `/PurchasedBlocks?token=${token}&CourseURLId=${courseURLId}`
    ).catch(error => {
        return error.response
    });
};

interface IPurchasedBlocksInstance {
    blockId: number;
    courseId: number;
    courseUrlid: string;
    isUserFullCourseAvailable: number;
    purchaseDate: string;
    userId: string;
    blockUrlid: string;
}

export const getPurchasedBlocksTyped = async (
    token: string | null,
    courseURLId: string
) => {
    try {
        return await userCoursesApi.get<IPurchasedBlocksInstance[]>(
            `/PurchasedBlocks?token=${token}&CourseURLId=${courseURLId}`
        );
    } catch (e) {
        console.log(e)
    }
};

//Content api

export const getContentBlocks = async (
    token: string | null,
    blockURLId: string | undefined,
    courseURLId: string
) => {
    const response = await contentCoursesApi.get(
        `/Block?token=${token}&CourseURLId=${courseURLId}&BlockURLId=${blockURLId}`
    );
    return response;
};

export const getFilesBlocks = async (
    token: string | null,
    blockURLId: string | undefined,
    courseURLId: string
) => {
    try {
        const response = await contentCoursesApi.get(
            `/Materials?token=${token}&CourseURLId=${courseURLId}&BlockURLId=${blockURLId}`
        );
        return response;
    } catch (error: any) {
        // console.log(error);
        return error.response;
    }
};

export const getLongreads = async (
    token: string | null,
    blockURLId: string | undefined,
    courseURLId: string
) => {
    try {
        const response = await contentCoursesApi.get(
            `/Longreads?token=${token}&CourseURLId=${courseURLId}&BlockURLId=${blockURLId}`
        );
        return response;
    } catch (error: any) {
        // console.log(error);
        return error.response;
    }
};

export const buyBlock = async (
    token: any,
    CourseURLId: string,
    BlockURLId: string
) => {
    let pass: any = prompt("Введите");

    const response = userCoursesApi.post(
        "/BuyBlock",
        JSON.stringify({
            token: token,
            CourseURLId: CourseURLId,
            BlockURLId: BlockURLId,
            SecretKey: pass,
        }),
        {
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            },
        }
    );
    console.log(response);
    return response;
};

export const buyCourse = async (
    token: any,
    CourseURLId: string,
    SecretKey: string
) => {
    const response = await userCoursesApi.post(
        "/BuyCourse",
        JSON.stringify({
            token: token,
            CourseURLId: CourseURLId,
            SecretKey: SecretKey,
        }),
        {
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            },
        }
    );
    console.log(response);
    return response;
};

export default coursesApi;
