import React, {useEffect, useRef} from 'react';
import ListSubscribes from "./components/ListSubscribes";
import {useLocation, useNavigate} from "react-router-dom";

const Subscription = () => {
    const ref = useRef(null)
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        if (location?.state?.ref == "subscriptions" && ref?.current) {
            setTimeout(() => {
                // @ts-ignore
                window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});
                navigate(location.pathname, {});
            }, 100)
        }
        // @ts-ignore
    }, [location?.state?.ref, ref.current?.offsetTop])

    return (
        <section ref={ref} style={{paddingBottom:"4rem", paddingTop:"2rem"}}>
            <div className="subscriptions">
                <p style={{fontWeight: 300}}>Тарифы</p>
                <ListSubscribes/>
            </div>
        </section>
    );
};

export default Subscription;
